import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import styled from 'styled-components'
import { Link } from 'gatsby'

import scssVars from '~components/scss-variables'
import Layout from '~components/Layout'
import { Teams, Testimonials } from '~components/About'
import Main from '~components/layouts/Main'
import SEO from '~components/seo'

import ownerImgDesktop from '../../img/about/Mesquita_image@2x.png'
import giantLogo from '../../img/LS-cropped.svg'

import barneyImg from '../../img/about/Press_Logos/BARNEYS_mobile@3x.png'
import glamourImg from '../../img/about/Press_Logos/GLAMOUR_mobile@3x.png'
import baazarImg from '../../img/about/Press_Logos/HARPERS-BAZAAR_mobile@3x.png'
import hollywoodImg from '../../img/about/Press_Logos/HOLLYWOOD-REPORTER_mobile@3x.png'
import instyleImg from '../../img/about/Press_Logos/INSTYLE_mobile@3x.png'
import goopImg from '../../img/about/Press_Logos/GOOP_mobile@3x.png'

const BreadcrumbList = styled.ul`
  color: ${scssVars.darkBrown};
  display: none;
  list-style: none;
  overflow: hidden;
  font-size: 16px;
  padding: 0;

  @media only screen and (max-width: 1280px) {
    margin-left: 50px;
    margin-top: 6px;
  }
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    display: block;
  }

  li {
    display: inline;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    letter-spacing: 0.025em;
    &:after {
      content: ' / ';
    }
    &:last-child:after {
      content: '';
    }
  }
`

const Breadbox = styled.div`
  padding: 20px 0 10px 0;
  ${BreadcrumbList} {
    margin-left: 0;
    li {
      font-family: 'Montserrat', san-serif;
    }
    a {
      color: ${scssVars.darkBrown};
      text-decoration: none;
      &:hover, &:focus {
        text-decoration: underline;
      }
    }
  }
`

const Heading = styled.h1`
  font-size: 28px;
  font-weight: 200;
  margin-bottom: 20px;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    font-size: 40px;
  }
`

const CobrandingLogo = styled.img`
  height: 50px;
  margin-right: 10px;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    height: 75px;
  }
`

const SectionHeading = styled.h2`
  font-weight: 200;
  color: ${scssVars.darkBrown};
  text-align: center;
  display: block;
  flex-wrap: wrap;
  display: flex;
  font-size: 44px;
  margin-bottom: 20px;
  line-height: 1.25;
  flex-direction: column;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    display: flex;
    flex-direction: row;
    text-align: left;
  }
`

const SectionSpan = styled.span`
  border: 1px solid ${scssVars.brown};
  height: 1px;
  margin: 0 auto 40px;
  width: 25%;
  display: block;
  margin: 25px auto;
  height: 1px;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    margin-left: 30px;
    margin-bottom: 0;
    flex-grow: 1;
  }
`

const SideStrip = styled.div`
  position: relative;
  display: inline-block;
  background-color: ${scssVars.gray};
  top: 50%;
  margin-top: -25%;
  &:before {
    position: absolute;
    content: '';
    width: 20%;
    height: 100%;
    top: 0;
    right: 100%;
    background-color: ${scssVars.gray};
  }
`

const TeamSection = styled.section`
  background-color: ${scssVars.gray};
  padding: 3rem 0;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    background-color: transparent;
  }
`

const PressSection = styled.section`
  text-align: center;
  p {
    font-size: 18px;
    line-height: 1.25;
  }
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    text-align: left;
    .btn {
      width: auto;
    }
  }
`

const StyledImg = styled.img`
  width: 100%;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    width: 580px;
    height: 504px;
    max-width: 680px;
    max-height: 604px;
  }
`

const StyledLogo = styled.img`
  width: 500px;
  position: absolute;
  top: -250px;
  left: 0;
`

const StyledTeamMemberTitle = styled.h3`
  @media only screen and (max-width: ${scssVars.desktopMin}) {
    color: ${scssVars.darkBrown};
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 0;
  }
`

const FounderSubtitle = styled.p`
  color: ${scssVars.darkBrown};
  font-family: 'Montserrat', sans-serif;
  font-style: italic;
  font-size: 16px;
  line-height: 1.25;
`

const TeamsImage = styled.div`
  display: none;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    display: block;
    position: absolute;
    height: 100%;
    max-height: 85vh;
    width: 50%;
    left: 0;
    top: 0;
    height: 100%;
    background: url('https://leatherspa.imgix.net/About-LS%20door@2x.png?fit=crop&crop=top&w=992&h=800&fm=jpg') center / cover;
  }
`

export default function AboutPage () {
  return (
    <Layout>
      <SEO title="About" pathname="/about" description="Find out more about Leather Spa's repair and care heritage" />
      <Main>
        <div className="d-lg-none">
          <Container fluid className="px-0">
            <img className="w-100" src={'https://leatherspa.imgix.net/Top_Image About@2x.png?scale=fit&w=922&fm=jpg'} alt="TODO" />
          </Container>
        </div>
        <div className="d-none d-lg-block">
          <Container>
            <Breadbox>
              <BreadcrumbList>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>About</li>
              </BreadcrumbList>
            </Breadbox>
            <img className="w-100" src={'https://leatherspa.imgix.net/Top_Image About@2x.png?scale=fit&w=1360&fm=jpg'} alt="TODO" />
          </Container>
        </div>
        <section className="position-relative">
          <StyledLogo className="d-none d-lg-block" src={giantLogo} alt="TODO" />

          <Container className="mt-3 mt-lg-5 mb-5">
            <Link className="d-block d-lg-none mb-3 text-clr-sambuca" to='/'>&#60; Home</Link>
            <Row>
              <Col lg={{ size: 7, offset: 5 }}>
                <Heading>Leather Spa Provides The Most Superior Repair Experience</Heading>
                <p className="mb-5">
                  Centered in our rich European heritage, artisanal expertise, unparalleled attention to detail, and dedication
                  to the finest materials available, Leather Spa provides the most superior repair experience. Our state-of-the-art
                  repair facility, proudly headquartered in New York City, features the most advanced repair and care technology
                  and our team of specialized artisans take pride in our exacting standards and unique level of craftsmanship.
                  We transform the ordinary to extraordinary.
                </p>
                <Row>
                  <Col className="d-flex align-items-center justify-content-end justify-content-lg-start stamp-row">
                    <CobrandingLogo src="https://leatherspa.imgix.net/Repaired With Love.svg" alt="repaired with love"/>
                    <CobrandingLogo src="https://leatherspa.imgix.net/LS_About_Logo-NYC.svg" alt="made in nyc"/>
                    <CobrandingLogo src="https://leatherspa.imgix.net/LS_About_Logo-LIC.svg" alt="LIC partnership"/>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <TeamSection className="my-lg-5">
          <Container>
            <SectionHeading className="mb-5 py-3">
              Our Team
              <SectionSpan />
            </SectionHeading>
            <Row>
              <Col lg={6}>
                <StyledImg src={ownerImgDesktop} alt="TODO"/>
              </Col>
              <Col lg={6} className="pl-lg-0">
                <div className="d-lg-none">
                  <StyledTeamMemberTitle className="mt-3 mb-2">Carlos &amp; David Mesquita</StyledTeamMemberTitle>
                  <FounderSubtitle>Founders</FounderSubtitle>
                  <p>
                    Father and son Carlos and David Mesquita are deeply committed to not only advancing, but elevating the leather repair and care industry. Through their exacting standards and tireless commitment to superior quality, they have built their humble family business into the distinguished and trusted industry leader: Leather Spa.
                  </p>
                </div>
                <div className="d-none d-lg-block">
                  <SideStrip className="py-5 pr-5 pl-0 bg-clr-cararra">
                    <StyledTeamMemberTitle className="mt-3">Carlos &amp; David Mesquita</StyledTeamMemberTitle>
                    <FounderSubtitle>Founders</FounderSubtitle>
                    <p>
                      Father-and-son owners Carlos and David Mesquita are steadfastly dedicated to evolving and elevating the
                      leather repair and care industry, while providing an exceptional service experience to their dedicated
                      clientele. Through their exacting standards, hard work, and commitment to superior quality, they have built
                      their humble family business into the world-renowned, industry leader: Leather Spa.
                    </p>
                  </SideStrip>
                </div>
              </Col>
            </Row>
          </Container>
        </TeamSection>
        <section className="position-relative bg-clr-sambuca text-white mb-5 mt-lg-5">
          <TeamsImage />
          <Container>
            <Teams />
          </Container>
        </section>
        <section>
          <Container>
            <SectionHeading className="mb-2 mb-lg-5 pt-lg-4">
              Testimonials
              <SectionSpan />
            </SectionHeading>
            <Testimonials/>
          </Container>
        </section>
        <section className="mb-sm-5">
          <Container className="px-0 px-lg-3">
            <img src={'https://leatherspa.imgix.net/image_factory@2x.png?scale=fit&w=1360&fm=jpg'} alt="TODO" style={{ width: '100%' }} />
          </Container>
        </section>
        <PressSection className="bg-clr-cararra text-clr-sambuca mt-5 py-5">
          <Container>
            <SectionHeading className="mb-3">
              In The Press
              <SectionSpan />
            </SectionHeading>
            <Row>
              <Col lg={{ size: 8, order: 2 }}>
                <Row>
                  {[barneyImg, glamourImg, baazarImg, hollywoodImg, instyleImg, goopImg].map((img, i) => (
                    <Col xs={4} key={i}>
                      <img src={img} className="w-100 px-lg-5" />
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col sm={{ size: 12 }} lg={{ size: 4, order: 1 }} className="d-flex flex-column justify-content-center">
                <p className="mt-3 mt-lg-0">
                  Read all about the latest Leather Spa news and care tips in our press coverage.
                </p>
                <div>
                  <Link to="/press" className="px-5 mb-3 btn btn-primary">Read More</Link>
                </div>
              </Col>
            </Row>
          </Container>
        </PressSection>
      </Main>
    </Layout>
  )
}
