import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Carousel as BootstrapCarousel,
  CarouselItem
} from 'reactstrap'
import { Link }  from 'gatsby'
import styled from 'styled-components'
import scssVars from '~components/scss-variables'

const StyledCarouselItem = styled(CarouselItem)`
  position: relative;
  opacity: 1;
  h3 {
    color: ${scssVars.orange};
    margin-top: 10px;
    font-size: 24px;
    font-weight: 600;
  }
  .info {
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    /* letter-spacing: -0.0005em; */
    letter-spacing: 0.025em;
    font-weight: 600;
    span {
      font-family: "EB Garamond", serif;
      font-size: 18px;
      line-height: 1.33;
      letter-spacing: 0.11em;
      font-weight: 400;
      text-transform: none;
    }
  }
  .info-value {
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: 0.11em;
  }
  hr {
    width: 100%;
    background-color: #655641;
    border-width: 1px;
  }
`

const ReadMoreLink = styled(Link)`
  display: block;
  color: ${scssVars.orange};
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.025em;
  font-style: italic;
  text-decoration: underline;
`

const Email = styled.a`
  font-family: inherit;
  font-size: inherit;
  color: inherit;
`

const Carousel = styled(BootstrapCarousel)`
  position: relative;
  padding-bottom: 20px;
`

const CarouselControlContainer = styled.div`
  position: absolute;
  height: 45px;
  z-index: 600;
  right: 0;
  @media only screen and (min-width: ${scssVars.mobileMin}) {
    .arrow-path {
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2px;
      stroke: white;
    }
    .mini-carousel-control {
      width: 30px;
      opacity: 1;
      background: none;
      border: none;
      &:focus {
        outline: 0;
      }
    }
  }
`

const StyledCarouselCol = styled(Col)`
  @media only screen and (min-width: ${scssVars.mobileMin}) {
    padding: 20px 20px 70px 20px;
  }
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    padding: 50px !important;
  }
`

const CarouselController = ({ next, previous }) => (
  <CarouselControlContainer className="pt-3">
    <button
      className="mini-carousel-control mini-carousel-control-prev"
      onClick={previous}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16.998"
        height="31.999"
        viewBox="0 0 16.998 31.999"
        color="white"
      >
        <path
          className="arrow-path"
          d="M-22256-12708.111l15,15-15,15"
          transform="translate(-22240.004 -12677.111) rotate(180)"
        />
      </svg>
      <span className="sr-only">Previous</span>
    </button>
    <button
      className="mini-carousel-control mini-carousel-control-next"
      onClick={next}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16.998"
        height="31.999"
        viewBox="0 0 16.998 31.999"
        color="white"
      >
        <path
          className="arrow-path"
          d="M-22256-12708.111l15,15-15,15"
          transform="translate(22257 12709.11)"
        />
      </svg>
      <span className="sr-only">Next</span>
    </button>
  </CarouselControlContainer>
)

CarouselController.propTypes = {
  next: PropTypes.func.isRequired,
  previous: PropTypes.func.isRequired
}

const items = [
  {
    title: 'REPAIR FACILITY & CORPORATE HQ',
    description: 'Headquartered in Long Island City, NY, our 20,000 square foot state-of-the art facility is the heartbeat of the Leather Spa company with dedicated teams to service each part of the repair and care journey.',
    tel: '718.392.9220',
    email: 'info@leatherspa.com',
    path: '/locations/repair-facility'
  },
  {
    title: 'THE PLAZA HOTEL STORE',
    description: 'Located on the lower mezzanine of the shops at the legendary Plaza Hotel.',
    name: 'Kevin',
    tel: '212.527.9944',
    email: 'plaza@leatherspa.com',
    path: '/locations/plaza-hotel'
  },
  {
    title: '55 WEST 55TH ST. STORE',
    description: 'Our flagship location located in the heart of Fifth Avenue’s shopping district.',
    name: 'Josephine',
    assistant: 'Fabian',
    tel: '212.262.4823',
    email: '55@leatherspa.com',
    path: '/locations/55-w-55th-street'
  },
  {
    title: 'SAKS FIFTH AVENUE STORE',
    description: 'Enlist the help of our leather care experts at the Saks Fifth Avenue Flagship Store.',
    name: 'Vincent',
    tel: '212.940.4843',
    email: 'saks@leatherspa.com',
    path: '/locations/saks-fifth-avenue'
  },
  {
    title: 'GRAND CENTRAL TERMINAL STORE',
    description: 'Located at the iconic Grand Central Terminal on the lower level Dining Concourse between tracks 104 and 105.',
    name: 'Eddie',
    tel: '212.661.0307',
    email: 'gct@leatherspa.com',
    path: '/locations/grand-central-terminal'
  },
  {
    title: 'GRAND CENTRAL TERMINAL STORE 2',
    description: 'Located at the iconic Grand Central Terminal in the Graybar Passage on Lexington Avenue.',
    name: 'Anna',
    tel: '212.687.3856',
    email: 'gct2@leatherspa.com',
    path: '/locations/grand-central-lexington'
  },
  {
    title: 'DOWNTOWN - FIDI STORE',
    description: 'Located in the heart of the worlds financial center near Wall Street.',
    name: 'Julio',
    tel: '212.233.0338',
    email: 'johnstreet@leatherspa.com',
    path: '/locations/downtown-fidi'
  }
]

const Teams = () => {
  const [index, setIndex] = useState(0)
  let animating = false
  const onExiting = () => {
    animating = true
  }
  const onExited = () => {
    animating = false
  }
  const next = () => {
    if (animating) return
    const nextIndex = index === items.length - 1 ? 0 : index + 1
    setIndex(nextIndex)
  }
  const previous = () => {
    if (animating) return
    const nextIndex = index === 0 ? items.length - 1 : index - 1
    setIndex(nextIndex)
  }
  return (
    <Row>
      <StyledCarouselCol lg={{ offset: 6, size: 6 }}>
        <Carousel
          activeIndex={index}
          next={next}
          previous={previous}
          interval={false}
        >
          {items.map((item, i) => {
            return (
              <StyledCarouselItem
                onExiting={onExiting}
                onExited={onExited}
                key={i}
              >
                <h3 className="mt-3">{item.title}</h3>
                <hr />
                <p className="info-value mb-4">{item.description}</p>
                {item.name && (<p className="info">Store Manager:&nbsp;<span>{item.name}</span></p>)}
                {item.assistant && (<p className="info">Assistant Manager:&nbsp;<span>{item.assistant}</span></p>)}
                <p className="info mb-1">Contact:</p>
                <p className="info-value mb-1">Tel:&nbsp;{item.tel}</p>
                <p className="info-value mb-1">Email:&nbsp;<Email href={`mailto:${item.email}`}>{item.email}</Email></p>
                <ReadMoreLink to={item.path} className="mt-4">Read More</ReadMoreLink>
              </StyledCarouselItem>
            )
          })}
          <CarouselController next={next} previous={previous} />
        </Carousel>
      </StyledCarouselCol>
    </Row>
  )
}

export default Teams
