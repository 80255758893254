import React, { useState } from 'react'
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem
} from 'reactstrap'
import { Link }  from 'gatsby'

import styled from 'styled-components'

import scssVars from '~components/scss-variables'
import MainTestimonial from '~components/Testimonials/MainTestimonial'
import '~components/all.scss'

const StyledQuoteLink = styled(Link)`
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 17px;
  text-decoration: underline;
  color: ${scssVars.orange};
`

const StyledTestimonial = styled.p`
 text-align: left;
  font-family: Caption 12px/18px 'EB Garamond', serif;
  letter-spacing: 0.54px;
  color: #301D0E;
  opacity: 1;
`

const CarouselControlContainer = styled.div`
  @import '../variables';
  @media only screen and (min-width: ${scssVars.mobileMin}) {
    position: absolute;
    bottom: 0;
    padding: 0;
    height: 45px;
    width: 100%;
    z-index: 600;
    .arrow-path {
      fill:none;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2px;
      stroke: black;

    }
    .mini-carousel-control {
      width: 30px;
      opacity: 1;
      background: none;
      border: none;
      &:focus {
        outline: 0
      }
    }
  }
`

const quotes = [{
  lead: 'I can\'t thank you enough for what a wonderful customer service experience this has been',
  text: 'I received my repaired shoe today via UPS. There were no surprise border fees. The packaging was lovely, it was like opening a present. The service and shipping was very quick. The repair is beautiful. I can’t thank you enough for what a wonderful customer service experience this has been.'
}, {
  lead: 'Very impressed with the quality of the repair',
  text: 'I just received my Louboutins back and was very impressed with the quality of the repair. Thank you so very much for such outstanding service, as well as the exceptional customer assistance throughout the process!'
}]

const Testimonials = () => {
  const [index, setIndex] = useState(0)
  let animating = false
  const onExiting = () => {
    animating = true
  }
  const onExited = () => {
    animating = false
  }
  const next = () => {
    if (animating) return
    const nextIndex = index === quotes.length - 1 ? 0 : index + 1
    setIndex(nextIndex)
  }
  const previous = () => {
    if (animating) return
    const nextIndex = index === 0 ? quotes.length - 1 : index - 1
    setIndex(nextIndex)
  }
  return (
    <div className="py-3">
      <MainTestimonial text={quotes[index].lead}/>
      <Container className="d-none d-lg-block">
        <Row className="my-5">
          <Col lg={2}>
            <CarouselControlContainer>
              <button className="mini-carousel-control mini-carousel-control-prev" onClick={previous}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16.998" height="31.999" viewBox="0 0 16.998 31.999" color="white"><path className="arrow-path" d="M-22256-12708.111l15,15-15,15" transform="translate(-22240.004 -12677.111) rotate(180)"/></svg>
                <span className="sr-only">Previous</span>
              </button>
              <button className="mini-carousel-control mini-carousel-control-next" onClick={next}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16.998" height="31.999" viewBox="0 0 16.998 31.999" color="white"><path className="arrow-path" d="M-22256-12708.111l15,15-15,15" transform="translate(22257 12709.11)"/></svg>
                <span className="sr-only">Next</span>
              </button>
            </CarouselControlContainer>
          </Col>
          <Col lg={10}>
            <Carousel
              activeIndex={index}
              next={next}
              previous={previous}
              interval={false}
            >
              {quotes.map((item, i) => (
                <CarouselItem onExiting={onExiting} onExited={onExited} key={i}>
                  <StyledTestimonial>{item.text}</StyledTestimonial>
                </CarouselItem>
              ))}
            </Carousel>
            <StyledQuoteLink to="/testimonials">
              Read More
            </StyledQuoteLink>
          </Col>
        </Row>
      </Container>
      <Link to="/testimonials" className="btn btn-primary my-5 d-lg-none">
        Read More
      </Link>
    </div>
  )
}

export default Testimonials
